.boxContainer {
    width: 75px;
    height: 85px;
}

.desktopNav {
    justify-content: space-between;
    right: 0;
    top: 30%;
    position: fixed;
}

.box {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out;
    transform-origin: 0 0;
}

.boxContainer:hover .box {
    transform: rotateY(-90deg);
    cursor: pointer;
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 0 0;
}

.front {
    transform: rotateY(0deg);
    background-color: #393e46;
    text-align: center;
    font-size: 3.5rem;
}

.right {
    transform: rotateY(90deg);
    left: 75px;
    background: #393e46; /*#808080;  fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(360deg, rgba(128,128,128,1) 0%, rgba(64,64,64,1) 46%, rgba(128,128,128,1) 100);
    font-size: 1.3rem;
    text-align: center;
}

.textStyle {
    text-decoration: none;
    color: #fff;
    text-align: center;
    margin-top: 30% !important;
}

.noPointer {
    pointer-events: none; 
}
.disabled {
    opacity: 0.5;
}


/* Mobile navbar ----------------------- */
.mobileNavbar {
    overflow: hidden;
}
.menu-icon .menu {
    display: block;
    color: #fff;
    font-size: 60px;
    position: fixed;
    left: 0; 
    cursor: pointer; 
    z-index: 3;
}

.slider {
    position: fixed;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    display: flex;
    background-color: #393e46;
    transition: 0.65s ease;
    z-index: 6;
    cursor: pointer;
}

.slider.active {
    left: 0;
    transition: 0.65s ease;
}

.slider ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 13%;
}
.slider ul li {
    margin-block: 1rem;   
    font-size: 3rem;
    list-style: none;     
}
.slider ul li a {
    text-decoration: underline;
    color: #fff;
}


.closedMobileNavbar {
    color: #fff;
    width: 100%;
}

.closedMobileNavbar .close {
    font-size: 30px;
    position: absolute;
    top: 2%;
    left: 2%;
}

@media only screen and (max-width: 767px) {
    .desktopNav {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .mobileNavbar {
        display: none;
    }
}