.container {
    width: 100%;
    display: flex;
    align-items: center;
}

/* .myImage {
    background-image: url('../../Assets/aram.png'); 
    background-size: cover;
    width: 40%;
    height: 60%;
    border-radius: 40%;
    margin-top: 5%;
    position: fixed;
} */

.myTextContainer {
    position: relative;
    justify-content: center;    
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    height: 80vh;
    letter-spacing: 0.5px;
    margin-right: 10%;
}
/* .myTextContainer {
    position: relative;
    justify-content: center;
    font-family: "Montserrat";
    text-align: center;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    letter-spacing: 0.5px;
}
.myText {
    margin: 0 0;
    background-image: url('../../Assets/aram.png');
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
    font-size: 170%;
    line-height: .75;
} */

.nonWeatherContainer {
    z-index: 1;
    width: 100vh;
    height: 100vh;
}

/* .mySnowyWeatherContainer {
    background-image: url('../../Assets/alps.jpg');
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: -1;
} */

/* .mySnowyWeather {

} */

/* .myRainyWeatherContainer {

} */

.myRainyWeather {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/rain3.png'); /*<a href="https://lovepik.com/images/png-rain.html">Rain Png vectors by Lovepik.com</a>*/
    animation: myRainyWeather 1.2s linear infinite;
    animation-direction: reverse;
}
@keyframes myRainyWeather {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 20% 100%;
    }
}
.myRainyWeather::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/lightning.png'); /*<a href="https://lovepik.com/images/png-electricity.html">Electricity Png vectors by Lovepik.com</a>*/
    top: -100px;
    transform: rotate(180deg);
    animation: thunderBuddy 4s linear infinite;
    opacity: 1;
}
@keyframes thunderBuddy {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    21% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 1;
    }
    35% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.mySunnyWeatherContainer {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    animation: sky 10s forwards;
    overflow: hidden;
}

.mySunnyWeather {
    position: absolute;
    top: 70%;
    left: 7%;
    height: 200px;
    width: 200px;
    background-color: #E4D00A;
    border-radius: 50%;
    box-shadow: 0 0 20px 10px #E4D00A;
    animation: sun 15s forwards;
}
@keyframes sun {
    100% {
        transform: translateY(-60vh);
    }
}
@keyframes sky {
    0% {
        background-color: #FF4500;  
    }
    50% {
        background-color: #FF8C00;
    }
    100% {
        background-color: #FFA500;
    }
}

/* .skyline {
    position: absolute !important;
    bottom: 0;
    left: 0;
    margin-left: 15%;
    background-image: url('../../Assets/mountains.svg'); /*this is another image -> <a href="https://lovepik.com/images/png-city.html">City Png vectors by Lovepik.com</a>*/
    /* background-size: cover; */
    /* width: 75%;1150px;
    height: 35%;700px;
}  */

.creditsToggle {
    justify-content: space-between;
    right: 3%;
    bottom: 5%;
    position: fixed;
}

.firstCloud { 
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: hidden;   
    background-image: url('../../Assets/cloud1.png'); /*<a href="https://lovepik.com/images/png-clouds.html">Clouds Png vectors by Lovepik.com</a> */ 
    background-size: cover;
    background-repeat: no-repeat;  
    animation: animateClouds 12s linear infinite;
}

.secondCloud { 
    position: absolute;
    top: 30%;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: hidden;   
    background-image: url('../../Assets/cloud1.png'); 
    background-size: cover;
    background-repeat: no-repeat;  
    animation: animateClouds 15s linear infinite;
}

.thirdCloud { 
    position: absolute;
    top: 0;
    bottom: 40%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: hidden;   
    background-image: url('../../Assets/cloud2.png'); /*<a href="https://lovepik.com/images/png-clouds.html">Clouds Png vectors by Lovepik.com</a> */ 
    background-size: cover;
    background-repeat: no-repeat;  
    animation: animateClouds 18s linear infinite;
}

@keyframes animateClouds {
    0%{
        transform: translateX(-100%);
    }
    
    100%{
        transform: translateX(100%);
    }
}

/* Small devices */
@media only screen and (max-width: 1100px) {
    #toggleContainerResponsive {
        font-size: 0.75rem;
    }
    .myTextContainer {
        margin-left: 10%;
    }
    .firstCloud {
        width: 100%;
        height: 42%;
        background-position: center;
        animation: animateClouds 6s linear infinite;
        margin-top: 40%;
    }
    .secondCloud {
        width: 100%;
        height: 42%;
        background-position: center;
        animation: animateClouds 9s linear infinite;
        margin-top: 40%;
    }
    .thirdCloud {
        width: 100%;
        height: 57%;
        background-position: center;
        animation: animateClouds 7.5s linear infinite;
    }
    .cloudsContainer {
        width: 50%;
        height: 200px;
        overflow: hidden;
    }
    .myRainyWeather {
        animation: myRainyWeather 1s linear infinite;
        animation-direction: reverse;
    }
    .myRainyWeather::before {
        background-position: top;
        transform: rotate(180deg);
        animation: thunderBuddy 4s linear infinite;
    }
}
