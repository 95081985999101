.creditsContainer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 1 !important;
    min-height: 100vh;
    color: #393e46;
    backdrop-filter: blur(4px);     
}

.creditsModal{
    align-items: center !important;
    cursor: default;
}

.creditsBody {
    font-size: 1rem !important;
    font-family: "neuemachina-ultrabold"; 
    font-style: normal; 
}