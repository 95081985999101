.App {
  text-align: center;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 767px) {
  #copyright {
    font-size: 0.75rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}