.particlesContainer {
    height: 50%;
    width: 100%;
    position: fixed;
    z-index: -100;
}

.iconsContainer {
    z-index: 1;
    width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.backend {
    display: inline-block;
    width: 10vw;
    height: 20vh;  
    top: 35%;
    right: 17%;
    border-radius: 50%;
    position: absolute;
    background-image: url('../../Assets/backend.png'); /*<a href="https://lovepik.com/images/png-background.html">Background Png vectors by Lovepik.com</a>*/
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: grab;
}

.backend:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 50%, #2596be, #2596be 14%, rgba(255, 255, 255, 0) 24%);
    animation: pulse-effect 5s ease-out infinite;
    filter: blur(25px);
}


.circle {
  display: inline-block;
  width: 10vw;
  height: 20vh;  
  top: 35%;
  left: 15%;
  border-radius: 50%;
  position: absolute;
  background-image: url('../../Assets/sphere.png'); /*<a href="https://lovepik.com/images/png-blue.html">Blue Png vectors by Lovepik.com</a>*/
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: grab;
}


.circle:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 50%, #00FFFF, #00FFFF 14%, rgba(255, 255, 255, 0) 24%);
    animation: pulse-effect 5s ease-out infinite;
    filter: blur(25px);
}

@keyframes pulse-effect {
    0% {
      transform: none;
    }
    60% {
        transform: none;
    }
    61% {
        transform: scale(3);
    }
    100% {
        transform: none;
    }
}

h3 {
    font-size: 6vh;  
    font-family: "neuemachina-ultrabold"; 
    font-style: normal; 
    font-weight: bold;
    letter-spacing: -0.01em; 
    color: #fff; 
}

.react {
    top: 23%;
    left: 30%;
    position: absolute;
    background-image: url('../../Assets/reactLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 18%;
    height: 13%;
}

.angular {
    top: 26%;
    left: 5%;
    position: absolute;
    background-image: url('../../Assets/angularLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 10%;
    height: 5%;
}

.typescript {
    bottom: 29%;
    left: 28%;
    position: absolute; 
    background-image: url('../../Assets/typescriptLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 10%;
    height: 5%;
}

.bootstrap {
    bottom: 27%;
    left: 6%;
    position: absolute; 
    background-image: url('../../Assets/bootstrapLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 15%;
    height: 8%;
}

.python{
    top: 24%;
    right: 18%;    
    position: absolute;
    background-image: url('../../Assets/pythonLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 18%;
    height: 6%;
}

.csharp {
    top: 23%;
    right: 4%;
    position: absolute;
    background-image: url('../../Assets/csharpLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 10%;
    height: 8%;
}

.java {
    bottom: 27%;
    right: 6%;
    position: absolute; 
    background-image: url('../../Assets/javaLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 10%;
    height: 12%;   
}

.sql {
    bottom: 27%;
    right: 17%;
    position: absolute; 
    background-image: url('../../Assets/sqlLogo.svg'); 
    background-size:contain;
    background-repeat: no-repeat;
    width: 15%;
    height: 9%;
}

.fadingEffect {
    position:absolute;
    width:10%;
    -moz-animation: showHide 1s ease-in normal 1; /* Firefox */
    -webkit-animation: showHide 1s ease-in normal 1; /* Safari and Chrome */
    -ms-animation: showHide 1s ease-in normal 1; /* IE10 */
    -o-animation: showHide 1s ease-in normal 1; /* Opera */
    animation: showHide 1s ease-in normal 1;
}
@-webkit-keyframes showHide { /* Chrome, Safari */
    0% {width:0%}
    100% {width:10%}
}
@-moz-keyframes showHide { /* FF */
    0% {width:0%}
    100% {width:10%}
}
@-ms-keyframes showHide { /* IE10 */
    0% {width:0%}
    100% {width:10%}
}
@-o-keyframes showHide { /* Opera */
    0% {width:0%}
    100% {width:10%}
}
@keyframes showHide {
    0% {width:0%}
    100% {width:10%}
}

.creditsToggleContainer {
    justify-content: space-between;
    right: 3%;
    bottom: 5%;
    position: fixed;
    z-index: 5;
}

.creditsToggle {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: none;
    color: #393e46;
    transition: all .5s ease;
    font-family: "neuemachina-ultrabold" !important;
    font-size: 2vh !important;
}


/* Small devices */
@media only screen and (max-width: 600px) { 
    .circle {
        width: 24vw;
        max-height: 40vh;  
        top: 42%;
    }
    .circle:after {
        top: -27%;
    }
    .backend {
        width: 24vw;
        max-height: 40vh;  
        top: 42%;
        right: 15%;
    }
    .backend:after {
        top: -27%;
        width: 81.5%;
    }
    
    .react {
        width: 13%;
        height: 11%;
    }
    .angular {
        width: 21%;
        height: 10%;
    }  
    .typescript {
        width: 22%;
        height: 4%;
    }
    .bootstrap {
        width: 13%;
        height: 7%;
    }
    .python{
        width: 30%;
        height: 8%;
    }
    .sql {
        right: 26%;
        height: 8%;
    }
}
/* Phones/Ipads etc */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .circle {
        width: 20vw;
        max-height: 36vh;  
        top: 38%;
    }
    .backend {
        width: 20vw;
        max-height: 36vh;  
        top: 38%;
        right: 15%;
    }
    .backend:after {
        width: 87.5%;
    }
    
    .react {
        width: 13%;
        height: 11%;
    }
    .angular {
        width: 19%;
        height: 10%;
    }  
    .typescript {
        width: 22%;
        height: 4%;
    }
    .bootstrap {
        width: 12%;
        height: 7%;
    }
    .python{
        width: 26%;
        height: 8%;
    }
    .sql {
        right: 26%;
        height: 8%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .circle {
        width: 16vw;
        max-height: 32vh;  
        top: 38%;
    }
    .backend {
        width: 16vw;
        max-height: 32vh;  
        top: 38%;
        right: 17%;
    }

    .react {
        width: 13%;
        height: 11%;
        left: 37%;
        top: 24%;
    }
    .angular {
        width: 16%;
        height: 10%;
    }  
    .typescript {
        width: 22%;
        height: 5%;
        left: 27%;
        bottom: 28%;
    }
    .bootstrap {
        width: 16%;
        height: 8%;
    }
    .python{
        width: 26%;
        height: 8%;
        right: 20%;
    }
    .sql {
        right: 24%;
        height: 8%;
    }
    .java {
        right: 7%;
    }
}