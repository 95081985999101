.aboutContainer {
    width: 100%;
    display: grid;
    align-items: center;
    text-align: justify;
    background: #404040;
    grid-template-columns: 50% auto;
    grid-template-rows: 40% 40% min-content;
    grid-gap: 5vh 5vh;
    padding: 10px 5%;
    cursor: none;
    font-size: 63%;
}

.myIntroText {
    font-family:'Times New Roman', Times, serif;
    width: 80%;
    text-align: center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-style: dotted;
}

.firstImage {
    background-image: url('../../Assets/aram.png'); 
    background-size: cover;
    width: 30vw;
    height: 100%;
    margin-top: 7%;
    border-radius: 20%;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    -webkit-box-shadow: 11px 11px 27px #4d4d4e, -11px -11px 27px #818182;
    box-shadow: 11px 11px 27px #4d4d4e, -11px -11px 27px #818182;
}

.secondImage {
    background-image: url('../../Assets/aram2.jpg'); 
    background-size: cover;
    background-position: center;
    width: 30vw;
    height: 80%;
    border-radius: 20%;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    -webkit-box-shadow: 11px 11px 27px #4d4d4e, -11px -11px 27px #818182;
    box-shadow: -11px -11px 27px #818182, 11px 11px 27px #4d4d4e;
}

.whoamiText {    
    font-family:'Times New Roman', Times, serif;
    width: 80%;
    text-align: center;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    border-style: dotted;
}

.socialMediaContainer {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    text-align: center;
    padding-left:20%;
    transform: translate(-50%, -50%);
    display: flex;
}
.socialIcons {
    position: relative;
    display: block;
    margin: 10px;
    width: 80px;
    height: 80px;
    font-size: 1.9rem;
    text-align: center;
    line-height: 80px;
    text-decoration: none;
    color: #000;
    transform: .5s;
}
span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px dashed #000;
    animation: 1s ease-in-out;
    transform: scale(0);
}
a:nth-child(1) span , a:nth-child(1) {
    border-color: #E1306C;
}
a:nth-child(2) span , a:nth-child(2) {
    border-color: #fafafa;
    color: #fafafa;
}
a:nth-child(3) span , a:nth-child(3) {
    border-color: #0077b5;
    color: #0077b5;
}
@keyframes effect {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
a:hover span{
    animation-name: effect;
    transform: scale(1);
}
a:hover{
    transform:scale(1.2);
}

.cursor {
    position: fixed;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events:none;
}

.cursors .cursor:nth-child(1) {
    background-color: #E1306C;
    z-index: 100003;
}
.cursors .cursor:nth-child(2) {
    background: #fafafa;
    z-index: 100002;
    height: 15px;
    width: 15px;
}

.cursors .cursor:nth-child(3) {
    background: #0077b5;
    z-index: 100001;
    height: 15px;
    width: 15px;
}

.bfLink {
    color: orangered !important;
    text-decoration: underline;
}

.bfLink:hover {
    text-decoration: none;   
}

.creditsToggleContainer {
    justify-content: space-between;
    right: 3%;
    bottom: 5%;
    position: fixed;
}

.creditsToggle {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: none;
    color: #393e46;
    transition: all .5s ease;
    font-family: "neuemachina-ultrabold" !important;
    font-size: 2vh !important;
}

.linkedinIcon {
    color: #0077b5;
}

.githubIcon {
    color: #fafafa;
}

.instagramIcon {
    color: #E1306C;
}


/* Small devices & Ipads etc*/
@media only screen and (max-width: 767px) {
    .aboutContainer {
        display: block;
        font-size: 50%;
        width: 100%;
        height: 100%;
        font-size: 63%;
        overflow: hidden;
        overflow-y: scroll;
    }
    .myIntroText {
        width: 100%;
        margin-top: 15%;
        margin-bottom: 15%;
    }
    .firstImage {
        width: 100%;
        max-height: 50%;
        margin-top: 7%;
        border-radius: 20%;
        background-position: center;
    }
    .secondImage {
        width: 100%;
        max-height: 50%;
        margin-top: 7%;
        border-radius: 20%;
        background-position: center;
    }
    .whoamiText {
        width: 100%;
        margin-top: 15%;
    } 
    .socialMediaContainer {
        margin-top: 12vh;
        margin-left: 28vw;
    }
    .socialIcons {
        margin: 1.5rem;
    }
    .cursors {
        display: none;
    }
}
/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .aboutContainer {
        display: block;
        font-size: 50%;
        width: 100%;
        height: 100%;
        font-size: 83%;
        overflow: hidden;
        overflow-y: scroll;
    }
    .myIntroText {
        width: 100%;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .firstImage {
        width: 100%;
        max-height: 70%;
        margin-top: 7%;
        border-radius: 20%;
        background-position: center;
    }
    .secondImage {
        width: 100%;
        max-height: 70%;
        margin-top: 7%;
        border-radius: 20%;
        background-position: center;
    }
    .whoamiText {
        width: 100%;
        margin-top: 10%;
    } 
    .socialMediaContainer {
        margin-top: 12vh;
        margin-left: 38%;
    }
    .socialIcons {
        margin: 3rem;
        font-size: 2.4rem;
    }
    .cursors {
        display: none;
    }
}
